import React, {useEffect, useRef} from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Banner from "../../components/banner"
import c1 from "../../../static/img/incursion-mercado/c1.jpg"
import c2 from "../../../static/img/incursion-mercado/c2.jpg"
import c3 from "../../../static/img/incursion-mercado/c3.jpg"

const Contenido = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="Market Entry Program"/>
            <Banner title="Market Entry Program"/>
            <section className="about_consulting_area bg-white pt-5 pb-5">
                <div className="container">
                    <div className="row strategy_info">
                        <div className="col-lg-7 order-1 order-lg-0">
                            <div className="ex_content">
                                <h3>Client’s profile</h3>

                                <p>A successful shipping company, which greatly scaled
                                    up its tanker
                                    traffic thanks to the sharp increase in crude oil prices between 2009 and 2014,
                                    plans to
                                    acquire a new
                                    fleet over the next three years. For this reason, its CFO recommended to the CEO
                                    (majority shareholder
                                    and Executive Chair) to explore a deeper market knowledge, develop a wider network
                                    of
                                    institutional
                                    investors, as well as expand their compliance capabilities with applicable
                                    regulations
                                    and best
                                    corporate governance practices. The latter with the purpose of having a more
                                    competitive
                                    capital
                                    structure to fund the purchase of tankers at a lower cost, without pressuring the
                                    company’s cash flows.</p>
                            </div>
                        </div>
                        <div className="col-lg-5 order-0 order-lg-1 text-center">
                            <img className="video_img wow fadeInRight" src={c1} alt={" "}/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about_consulting_area bg-white">
                <div className="container">
                    <div className="row strategy_info">
                        <div className="col-lg-5">
                            <img className="video_img w-100 wow fadeInLeft" src={c2} alt={" "}/>
                        </div>
                        <div className="col-lg-7">
                            <div className="ex_content">
                                <h3>Mandate</h3>

                                <p>On recommendation by a client of IRStrat, the company
                                    contracted
                                    the Market-entry Program solution, with the mandate to assist in defining the
                                    optimal
                                    instrument to be
                                    issued and the most effective go-to-market strategy, as well as develop the
                                    capabilities
                                    necessary in
                                    the company to execute the placement.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pt-5 pb-0">
                <div className="container ex_content">
                    <div className="row m-0">
                        <div className="col-md-12 pl-3 pl-lg-0">
                            <h3 className="s_title d-block">Solution</h3>
                        </div>
                    </div>
                </div>
            </section>
            <section className="executive_area pt-3 pb-4">
                <div className="container">
                    <div className="row mt-3 step service_right_sidebar p-0 mb-5 d-flex align-items-center">
                        <div className="col-lg-7">
                            <div id="accordion7" role="tablist" aria-multiselectable="true"
                                 className="panel-group faq-accordion service_accordion">
                                <div className="card active">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a data-toggle="collapse" data-parent="#accordion7" href="#collapse25"
                                               className="btn-accordion"
                                               aria-expanded="true" role="button" aria-controls="collapse25">
                                                <span className="pluse">+</span><span className="minus">-</span>Strategy
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse25" className="collapse show" aria-expanded="true" role="tabpanel">
                                        <div className="card-block panel-body"><p>IRStrat performs an initial assessment
                                            of the client's
                                            issuance capabilities, then, using the collected information, makes an ad
                                            hoc checklist of the
                                            objectives to be achieved, and defines the work schedule best suited for the
                                            company’s regular
                                            activities. </p>
                                            <p>Our team, working with the senior management, begins to develop the
                                                company’s investment
                                                thesis, history and competitive advantage, and design the IR
                                                department’s structure and map the
                                                financial reporting functions.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a role="button" data-toggle="collapse" data-parent="#accordion7"
                                               href="#collapse26"
                                               className="btn-accordion collapsed" aria-expanded="false"
                                               aria-controls="collapse26">
                                                <span className="pluse">+</span><span className="minus">-</span>Tactic
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse26" className="panel-collapse collapse" aria-expanded="false"
                                         role="tabpanel">
                                        <div className="panel-body">IRStrat conducts a thorough training of our client's
                                            executive team,
                                            covering finance, communication and regulatory framework fields. The
                                            earnings release, annual
                                            report, corporate presentation and IR site are developed, as well as the
                                            company's corporate
                                            governance policies. After performing an intelligence report on the industry
                                            and market where our
                                            client operates, with the objective to better understand the intricacies of
                                            our client’s business
                                            and identify the potential players who will participate in the placing,
                                            IRStrat initiates a direct
                                            and effective approaching with those actors (underwriters, investors, credit
                                            rating agencies and
                                            specialized media).
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a role="button" data-toggle="collapse" data-parent="#accordion7"
                                               href="#collapse27"
                                               className="btn-accordion collapsed" aria-expanded="false">
                                                <span className="pluse">+</span><span className="minus">-</span>Results
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse27" className="panel-collapse collapse" aria-expanded="false"
                                         role="tabpanel">
                                        <div className="panel-body">Thanks to IRStrat’s negotiation skills and
                                            management capacity, our
                                            client receives multiple issuance proposals of 500 to 650 million pesos in
                                            unsecured local notes,
                                            250 to 350 basis points below the financial cost of the second-best option,
                                            in this case, a
                                            syndicated loan. Additionally, our client develops the capabilities to
                                            comply with applicable
                                            stock market regulation in Mexico, the best corporate governance practices
                                            and reporting scope to
                                            carry out the placement of unsecured local notes; also preparing the
                                            groundwork for a potential
                                            Eurobond issuance or an IPO in the international capital markets.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 text-center mt-4 mt-lg-0">
                            <img className="img-fluid w-100 wow fadeInRight" src={c3} alt={" "}/>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Contenido
